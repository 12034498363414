import { render, staticRenderFns } from "./EditModal.vue?vue&type=template&id=53ff5625&scoped=true&"
import script from "./EditModal.vue?vue&type=script&lang=js&"
export * from "./EditModal.vue?vue&type=script&lang=js&"
import style0 from "./EditModal.vue?vue&type=style&index=0&id=53ff5625&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ff5625",
  null
  
)

export default component.exports