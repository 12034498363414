<template>
  <b-modal
    id="modal-normative"
    centered
    :title="
      !isEdit
        ? $t('client.enter_document_type')
        : $t('client.edit_document_type')
    "
    no-close-on-backdrop
    @show="openModal"
  >
    <validation-observer ref="DocumentTypeObserver">
      <b-form-group :label="$t('manual.doc_type')" class="my-1">
        <validation-provider
          #default="{ errors }"
          :name="$t('client.enter_document_type')"
          rules="required"
        >
          <b-form-input
            v-model="modalValue.title"
            :placeholder="$t('client.enter_document_type')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
    <div>
      <b-form-checkbox
        class="custom-control-primary"
        name="check-button"
        id="checkbox-1"
        switch
        v-model="modalValue.is_active"
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="XIcon" />
        </span>
      </b-form-checkbox>
    </div>

    <div slot="modal-footer">
      <b-button
        class="mx-1"
        variant="danger"
        @click="$bvModal.hide('modal-normative')"
      >
        {{ $t('client.cancel') }}
      </b-button>
      <b-button :variant="isEdit ? 'warning' : 'success'" @click="onSubmit">
        {{ isEdit ? $t('change') : $t('save') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
  import {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BFormCheckbox,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'

  import { required } from '@validations'
  import { mapActions } from 'vuex'

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BButton,
      BModal,
      ValidationProvider,
      ValidationObserver,
      BFormCheckbox,
    },

    data() {
      return {
        id: '',
        modalValue: {
          title: '',
          is_active: true,
        },
        required,
      }
    },

    props: {
      isEdit: {
        type: Boolean,
        required: true,
        default: false,
      },

      docTypeData: {
        type: Object,
        required: false,
        default() {
          return {}
        },
      },
    },

    methods: {
      ...mapActions('knowledgeBase', ['CREATE_BILL_TYPE', 'UPDATE_BILL_TYPE']),

      openModal() {
        if (!this.isEdit) {
          this.modalValue.title = ''
          this.modalValue.is_active = true
        } else {
          this.modalValue.title = this.docTypeData.title
          this.modalValue.is_active = this.docTypeData.is_active
        }
      },

      async onSubmit(bvModalEvt) {
        bvModalEvt.preventDefault()

        const res = await this.$refs.DocumentTypeObserver.validate()

        if (!res) {
          return
        }

        try {
          let ACTION = this.isEdit
            ? this.UPDATE_BILL_TYPE
            : this.CREATE_BILL_TYPE

          await ACTION({
            data: this.modalValue,
            id: this.docTypeData.id,
          })
          this.$emit('refresh')

          this.$nextTick(() => {
            this.$_successToast(this.$t('general.success'))
            this.$bvModal.hide('modal-normative')
          })
        } catch (error) {
          console.log(error)
          this.$_errorToast(this.$t('error'))
        }
      },
    },
  }
</script>

<style scoped>
  #username {
    text-transform: uppercase;
  }
</style>
